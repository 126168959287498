import '~/assets/styles/index.scss';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};

setTimeout(() => {
  (window.__ctm_loaded || []).push(function () {
    __ctm.form.capture(
      'app.calltrackingmetrics.com', // the capture host
      'FRT472ABB2C5B9B141A10C5DB3483155F9921CDDF2120D50BEDCFBEE21EC58BD581', // this FormReactor
      document.getElementById('form'), // ID of the web form you want to attach your FormReactor
      '8182736233', // the tracking number used for associating visitor data and caller ID consumers will see
      {
        country_code: '1', // the expected country code e.g. +1, +44, +55, +61, etc... the plus is ignored
        name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
        phone: 'phone', // ID of the lead phone number field
        email: 'email', // optional, ID of the lead email field
        fields: [
          'email_marketing',
          'fb',
          'google',
          'landing',
          'banner',
          'publication',
          'site_banner',
          'logo',
          'sponsorship',
          'comment',
          'package',
        ], // list of additional field IDs in the form you want to capture
      }
    );
  });
}, 1500);
